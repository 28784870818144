*:before,
*:after {
    box-sizing: border-box;
}

html {
    overflow: hidden;
}

.btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    padding: 1rem;
    font-size: 2rem;
    color: #6200ea;
    text-decoration: none;
    background: white;
    border: solid mix(white, #6200ea, 25%);
    border-width: 1px 1px 0;
    transform: translateX(-50%);
    z-index: 99;
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;

    &.animate {
        display: block;
        .bar {
            animation: rise-fall 1s ease-in-out forwards;
        }
    }

    .bar {
        position: absolute;
        width: 10.1vw;
        background: #6200ea;
        top: 0;
        bottom: 0;
        transform: scale(0, 1) translate3d(0, 0, 0);
        transform-origin: 100% 100%;
        transition: transform 0.5s ease-in-out;

        &:first-of-type {
            border-left: none;
        }

        $light: #6200ea;
        $dark: mix(black, #6200ea, 5%);

        @for $x from 1 through 10 {
            &:nth-of-type(#{$x}) {
                left: ($x - 1) * 10vw;
                background: linear-gradient(to right, $light 0%, $dark 100%);
                animation-delay: 0.05s * ($x - 1), 0.05s * (10 - $x); /* Adjust delay for sequential animation */
            }

            $light: $dark;
            $dark: mix(black, #6200ea, ($x * 5%));
        }
    }
}

@keyframes rise-fall {
    0% {
        transform: scale(0, 1) translate3d(0, 0, 0);
    }
    50% {
        transform: scale(1, 1) translate3d(0, 0, 0);
    }
    100% {
        transform: scale(0, 1) translate3d(0, 0, 0);
    }
}
