*{
  text-decoration: none;
  font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
}

a{
  text-decoration: none;
  color: #fff;
}

.inputRedirect{
  margin-top: auto;
  margin-bottom: auto;
}

.borderMenuBlock{
    position: fixed;
    background-color: rgb(22, 22, 22);
    width: 15%;
    height: 75px;
    display: block;
    border: 10px;
    border: solid, black;
    border-radius: 7px;
    margin: 3px;
}

.borderButton{
  text-align: left;
  width: 97%;
  height: 30px;
  border-radius: 7px;
  background-color: rgb(22,22,22);
  font-size: 18px;
  font-weight: bold;
  color: #707070;
  text-decoration: none;
  margin: 3px;
  display: inline-block;
  padding: 0 20px; 
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.borderImg{
  transform: translate(-50%, -50%);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; 
  background-color: gray;
  overflow: hidden;
  width: 60px;
  height: 60px;
  transition: all 1s ease;
}

.borderButton:hover {
  color: white; 
}

/* Focus состояние */
.borderButton:focus {
  color: white; 
}

body{
    background-color: black;
}
.container{
  width: 100%;
    color: white;
    border-color: black;
    font-family: Arial, Helvetica, sans-serif;
    background-color: black;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.leftPart{
    min-width: 15%;
    display: flex;
    border-color: black;
    flex-direction: column;
    margin-right: 10px;
}

.playlistMenuBlock {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  top: 85px;
  height: 82%;
  width: 15%;
  position: fixed;
  background-color: rgb(22, 22, 22);
  border: 10px;
  border: solid, black;
  border-radius: 7px;
  margin: 3px;
}
.playlistsBlock{
  display:inline ;
  max-height: 100%;
  overflow-y: scroll;
  scrollbar-width: thin;
}
.test{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  background-color: #fff ;
  border: 2px double #151515;
  border-radius: 7px;
  margin: 10px;
  width: 10rem;
  height: 10rem;
}

.test:hover{
  cursor: pointer;
}


.NewPlaylistForm-container {
  position: fixed;
  width: 30%;
  height: 30%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #151515;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.NewPlaylistForm-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  color: white;
}

.appBlock{
    width: 100%;
    background-color: rgb(22, 22, 22);
    border-color: black;
    border: solid, black;
    border-radius: 7px;
    margin: 3px;
}

  .custom-input {
    width: 300px;
    max-height: 20px;
    padding: 1px;
    border: 2px solid #000; /* черный цвет границы */
    border-radius: 7px;
    background-color: #fff; /* белый цвет фона */
    color: #000; /* черный цвет текста */
    font-size: 16px;
    outline: none; /* убираем стандартный фокус */
    transition: all 0.3s ease;
  }
  
  .custom-input:focus {
    border-color: #000; /* изменение цвета границы при фокусировке */
  }

  .round-button-container {
    position: relative;
    display: inline-block;
  }
  
  .round-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #000;
    color: white;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    transition: transform 0.2s ease; /* добавляем анимацию масштабирования */
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .round-button:hover {
    transform: scale(1.1); /* увеличиваем размер кнопки при наведении */
  }
  
  .menu {
    position: absolute;
    top: 50px; /* расположение меню под кнопкой */
    left: -130px;
    width: 150px;
    background-color: rgb(70, 70, 70); /* белый цвет фона */
    border: 2px solid rgb(70, 70, 70); /* черный цвет границы */
    border-radius: 5px;
    padding: 10px;
  }
  
  .menu p {
    margin: 5px 0;
    cursor: pointer;
  }
  
  .menu p:hover {
    background-color: #858585; /* изменение цвета фона при наведении */
  } 

  .PlayerBlock{
    max-height: 85px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: fixed;
    width: 100%;
    z-index: 5px;
    bottom: 0px;
  }
  
  .musicInfoBlock{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    width: 20%;
    height: auto;
    background-color: rgb(0, 0, 0);
    color: white;
  }
  .trackName{
    margin-left: 1vw;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }
  .artistP{
    color:#999
  }
  .trackImage{
    margin-left: 10%;
    transform: translate(-50%, -50%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
    background-color: gray;
    overflow: hidden;
    width: 60px;
    height: 60px;
    border: 2px solid black;
    border-radius: 50%;
    transition: all 1s ease;
    animation: rotate 5s linear infinite;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .centerCircle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1rem;
    height: 1rem;
    background-color: rgb(0, 0, 0);
    border: 2px double #707070;
    border-radius: 50%;
  }

  .upperMenuBlock{
    position: fixed;
    z-index: 50px;
    background-color: rgb(18, 18, 18);
    width: 84.5%;
    border: 10px;
    border: solid, black;
    border-radius: 7px;
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 5px;
  }
  
  .rightPart{
    min-width: 80%;
    width: 100rem;
    margin-left: 5px;
    margin-top: 3px;
  }
  
  .tableBlock {
    margin-top: 35px;
    background-color: rgb(22, 22, 22);
    padding: 10px;
    max-height: 100%;
    height: 85vh;
    overflow-y: scroll;
    scrollbar-width:auto;
  }

  .active-song{
    color:rgb(30, 200, 30);
  }

  .active-playlist{
    text-decoration: none;
    color:rgb(30, 200, 30);
  }

  .passive-playlist{
    text-decoration: none;
    color: #000;
  }
  
  table {
    width: 100%; 
    background-color: rgb(22, 22, 22);
    border-collapse: collapse;
  }

  .mainOpt{
    margin-top: 3em;
  }
  
  th, td {
    text-align: left;
    color: #fff;
  }
  
  th {
    background-color: rgb(22, 22, 22);
  }
  
  [class^="play-button"], .edit-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(22, 22, 22, 0);
    color: white;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    transition: transform 0.2s ease;
  }
  .edit-button {
    font-size: 22px;
    font-weight: bolder;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color:#707070 #222222 ;
  }

  *::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  *::-webkit-scrollbar-track {
    height: 5px;
    background: #222222;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 5px;
    border: 3px solid #222222;
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px; 
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  tr:hover{
    background-color: #464646;
  }
  
  .logging-container {
    position: fixed;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #222222;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 100;
  }
  
  .logging-content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    color: white;
  }
  
  .blur-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    z-index: 50;
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }
  .controlLoginFormButton, .swapbutton{
    width: 40%;
    color: white;
    border: 2px solid #303030;
    border-radius: 2px;
    background-color: #303030;
    margin-top: 10px;
    height: 30px;
    transition: transform 0.2s ease;
  }

  .inputLoginForm{
    height: 20px;
    margin-top: 10px;
  }

  .controlLoginFormButton:hover, .swapbutton:hover{
    background-color: #888;
  }
  .messageInBlock{
    color: red;
  }
.PlaylistDeleteSongButton{
  text-align: left;
  height: 30px;
  border-radius: 7px;
  background-color: rgb(22,22,22);
  font-size: 18px;
  font-weight: bold;
  color: #707070;
  text-decoration: none;
  display: inline-block;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.PlaylistDeleteSongButton:hover {
  color: white; 
}

.PlaylistDeleteSongButton:focus {
  outline: none;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
}

select {
    background-color: #222222;
    color: #fff;
    border: none;
    border-bottom: 2px solid #fff;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    transition: border-color 0.3s ease;
    outline: none;
}

select:hover {
    background-color: #333333;
}

select:focus {
    border-color: #aaa;
}

select option {
    background-color: #222222;
    color: #fff;
    padding: 8px;
}

select option:checked {
    background-color: #444444;
}

select::-ms-expand {
    display: none;
}

select::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #fff;
  pointer-events: none;
}

.MainPage {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: #f4f4f9; /* Light background */
  color: #333; /* Dark text */
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.menu {
  position: fixed;
  width: 300px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding: 20px;
  background-color: #6200ea; /* Primary color */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.menu h1 {
  margin-bottom: 0.5rem;
  color: #fff; /* White text */
  font-size: 1.5rem;
}

.menu h2 {
  margin-bottom: 1rem;
  color: #fff; /* White text */
  font-size: 1.2rem;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.profession {
  position: absolute;
  left: 0;
  font-size: 1.5rem; /* Slightly smaller text */
  white-space: nowrap;
  animation: moveRight 10s linear infinite, fadeInOut 10s forwards;
  color: #6200ea; /* Primary color */
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100vw);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.startButton {
  width: 100%;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #6200ea; /* Primary color */
  color: #fff; /* White text */
  font-size: 1rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.startButton:hover {
  background-color: #3700b3; /* Darker shade */
}

.startButton:focus {
  background-color: #3700b3; /* Darker shade */
  outline: none;
}

.hint {
  position: fixed;
  text-align: center;
  width: 300px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding: 20px;
  background-color: #fff; /* Primary color */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.pLogOut:hover{
  cursor: pointer;
}
.loader {
  font-size: 10px;
  color: #222222;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(100);
}
@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.5), -1.8em -1.8em 0 0em rgba(255,255,255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.5), 1.8em -1.8em 0 0em rgba(255,255,255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.5), 2.5em 0em 0 0em rgba(255,255,255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.5), 1.75em 1.75em 0 0em rgba(255,255,255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.5), 0em 2.5em 0 0em rgba(255,255,255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.5), -1.8em 1.8em 0 0em rgba(255,255,255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.5), -2.6em 0em 0 0em rgba(255,255,255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

.blur-background-colored {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #6200ea, #bb86fc, #ffffff);
  backdrop-filter: blur(5px);
  z-index: 50;
  transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
    }

    .gradient-background.show {
      opacity: 1;
      visibility: visible;
    }

.visionOn{
  visibility: visible;
}

.visionOff{
  visibility: hidden;
}

.progress-container {
  width: 90%;
  background-color: #e0e0e0;
  border-radius: 25px;
  overflow: hidden;
  border: 2px solid #000;
}

.progress-bar {
  width: 0;
  height: 30px;
  background-color: #6200ea;
  border-radius: 25px 0 0 25px;
  transition: width 0.5s ease-in-out;
}

.work-hours-container {
  display: flex;
  gap: 2px;
  margin: 10px 0;
}

.work-hour-bar {
  width: 10px;
  height: 20px;
  background-color: #6200ea;
}

.specialcard{
  background-color: #e0e0e0fb;
}